import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestLink, BestSelect, DateRangePickerWithLabel, PrimaryButton } from "best-common-react";

class TicketEventFilters extends React.Component {
  render() {
    const { eventsStore, requestSidebarStore, commonStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
          <DateRangePickerWithLabel
            value={commonStore.dateRangeFilter}
            onStartChange={commonStore.setDateRangeFilterStart}
            onEndChange={commonStore.setDateRangeFilterEnd}
            onChange={
              // do nothing
              () => {}
            }
            label="Date"
          />
        </div>
        <div className="col-xl-3 col-lg-2 col-md-6 col-sm-12">
          <BestLabel htmlFor="home-away">Type</BestLabel>
          <BestSelect
            id="home-away"
            options={eventsStore.homeAwayOptions}
            value={eventsStore.homeAwayFilter}
            onChange={eventsStore.setHomeAwayFilter}
          />
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
          <BestLabel htmlFor="opponent">Opponent</BestLabel>
          <BestSelect
            id="opponent"
            options={eventsStore.opponentOptions}
            value={eventsStore.opponentFilter}
            onChange={eventsStore.setOpponentFilter}
          />
        </div>
        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 mt-4">
          <PrimaryButton
            id="requestGames"
            onClick={() => requestSidebarStore.createRequests()}
            disabled={requestSidebarStore.selectedGames.length === 0}
          >
            Create Requests
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(TicketEventFilters));
