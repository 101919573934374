import { action, autorun, computed, extendObservable, decorate, reaction } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";

class RevokeDigitalTicketsStore {
  constructor(authStore, commonStore, compTixApi, routerStore, loadingStore, alertStore, eventReservationsStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;
    this.eventReservationsStore = eventReservationsStore;

    this.defaults = {
      selectedUserId: -1,
      selectedRequestId: -1,
      forwardId: "",
      showRevokeTicketsModal: false,
      showParkingPasses: false,
      gamePk: null,
      request: {},
      requestId: -1,
      allTickets: [],
      parkingPasses: [],
      selectedTickets: [],
      digitalTicketDelivery: null
    };

    extendObservable(this, {
      selectedUserId: this.defaults["selectedUserId"],
      selectedRequestId: this.defaults["selectedRequestId"],
      showRevokeTicketsModal: this.defaults["showRevokeTicketsModal"],
      showParkingPasses: this.defaults["showParkingPasses"],
      forwardId: this.defaults["forwardId"],
      gamePk: this.defaults["gamePk"],
      request: this.defaults["request"],
      requestId: this.defaults["requestId"],
      allTickets: this.defaults["allTickets"],
      digitalTicketDelivery: this.defaults["digitalTicketDelivery"],
      parkingPasses: this.defaults["parkingPasses"],
      selectedTickets: this.defaults["selectedTickets"],

      setSelectedUserId: action(userId => {
        this.selectedUserId = userId;
      }),
      setSelectedRequestId: action(selectedRequestId => {
        this.selectedRequestId = selectedRequestId;
      }),
      setShowRevokeTicketsModal: action(value => {
        this.showRevokeTicketsModal = value;
      }),
      setForwardId: action(value => {
        this.forwardId = value;
      }),
      setGamePk: action(value => {
        this.gamePk = value;
      }),
      setRequest: action(value => {
        this.request = value;
      }),
      setRequestId: action(value => {
        this.requestId = value;
      }),
      toggleShowParkingPasses: action(() => {
        this.showParkingPasses = !this.showParkingPasses;
      }),
      setShowParking: action(value => {
        this.showParkingPasses = value;
      }),
      setAllTickets: action(data => {
        this.allTickets = data;
      }),
      setDigitalTicketDelivery: action(data => {
        this.digitalTicketDelivery = data;
      }),
      setParkingTickets: action(data => {
        this.parkingPasses = data;
      })
    });

    reaction(
      () => this.showRevokeTicketsModal,
      () => {
        if (!this.showRevokeTicketsModal) {
          this.setAllTickets([]);
          this.setParkingTickets([]);
        }
        if (this.showRevokeTicketsModal) {
          this.compTixApi.getTicketRequest(this.requestId).then(data => {
            this.setRequest(data);
          });
        }
      }
    );
  }

  get displayableTickets() {
    return this.allTickets.toJSON().concat(this.parkingPasses.filter(t => t.isSelected));
  }

  get selectedParkingPasses() {
    return this.parkingPasses.toJSON().filter(t => t.isSelected);
  }

  revokeTickets = callback => {
    //teamId, gamePk, orgId, forwardId, ticketIds, email
    this.loadingStore.setLoading(true);
    let statsId;
    if (this.authStore.isBoxOfficeAdmin) {
      statsId = this.request.requesterOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
    } else {
      statsId = this.commonStore.currentOrg.statsId || this.eventReservationsStore.game.teams.home.team.id;
    }
    this.compTixApi
      .revokeTickets(
        statsId,
        this.gamePk,
        this.request.requesterOrg.allStarTeam
          ? 15
          : this.request.requesterOrg.statsId == null
          ? 0
          : this.request.venueSeason.venueSeasonKeyId.orgId,
        this.forwardId,
        this.digitalTicketDelivery.ticketIds?.toJSON(),
        this.request.deliveryEmail,
        {
          recipientEmail: this.request.deliveryEmail,
          recipientFirstName: this.request.recipientFirstName,
          recipientLastName: this.request.recipientLastName
        },
        this.requestId
      )
      .then(data => {
        callback().then(results => {
          this.loadingStore.setLoading(false);
          this.setShowRevokeTicketsModal(false);
          this.setShowParking(false);
        });
      });
  };
}

decorate(RevokeDigitalTicketsStore, {
  displayableTickets: computed,
  selectedParkingPasses: computed
});

export default RevokeDigitalTicketsStore;
